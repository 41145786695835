@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/lightmode';

.edit-irregular-day {
  margin-bottom: 140px;

  &__top {
    padding: 0 spacing.$l;
  }

  &__card {
    margin-top: spacing.$l;
    border-radius: spacing.$s;
    background-color: lightmode.$background-surface-primary;

    &--no-background {
      border-radius: 0;
      background-color: transparent;
    }
  }

  &__card-section {
    &:not(:last-child) {
      border-bottom: 1px solid lightmode.$ui-border;
    }

    &--padded {
      padding: spacing.$l;
    }
  }

  &__toggle-wrapper {
    display: flex;
    gap: spacing.$xs;
    align-items: center;
    margin-bottom: spacing.$s;
    border-top: 1px solid lightmode.$ui-border;
    padding-top: spacing.$l;
  }

  &__toggle-description {
    color: lightmode.$on-surface-secondary;
  }

  &__card-section-inner {
    &:first-child {
      padding-bottom: spacing.$l;
      border-bottom: 1px solid lightmode.$ui-border;
    }

    &:last-child {
      padding-top: spacing.$l;
    }
  }

  &__card-date-selector {
    display: flex;
    padding-top: spacing.$l;
  }

  &__buttons {
    position: fixed;
    bottom: 0;
    background-color: lightmode.$background-surface-primary;
    padding: spacing.$m;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: spacing.$s;
  }
}
